
function Menu() {
    //make it dynamic
    return (
        <div className="menu">
            <div className="menu-item">
                <a href="#">New Arrivals</a>
            </div>
            <div className="menu-item">
                <a href="#">Test2</a>
            </div>
            <div className="menu-item">
                <a href="#">menu item</a>
            </div>
            <div className="menu-item">
                <a href="#">About</a>
            </div>
            <div className="menu-item">
                <a href="#">menu item</a>
            </div>
        </div>
    )
}

export default Menu;