import Home from "./components/Home";
import Detail from "./components/Details";
import "./App.scss";


function App() {
    return (
        <div className='app'>
            <Home />
        </div>
    )
}

export default App;